import { useEffect, useState } from 'react';
import { HiBadgeCheck } from 'react-icons/hi';
import {
  AiFillCheckCircle,
  AiOutlineHeart,
  AiFillHeart,
  AiFillExclamationCircle,
} from 'react-icons/ai';
import { Button } from 'react-bootstrap';
import urlcat from 'urlcat';
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';
import { isString } from 'lodash';
import { useRouter } from 'next/router';
import axios from '../utils/axios';
import { COLOURS } from '../styles/Constants';
import { DetailedProfile, BasicProfile } from '../interfaces';
import { TopBadge } from './TopBadge';
import { BookMeetingButton } from './Buttons/BookMeetingButton';
import styles from './ProfileViews.module.css';
import WishListButton from './Buttons/WishListButton';
import { MoreInfoButton } from './Buttons/MoreInfoButton';
import useUserInfo from '../hooks/useUserInfo';
import logger from '../utils/logger';
import { getCooeeApiUrl } from '../utils/api';
import { getHostEnv } from '../utils/api';
import { updateTopic } from '../store/actions/topicActions';

export const BasicProfileView = (props): JSX.Element => {
  const {
    firstName,
    profilePictureUrl,
    isVerified,
    lastName,
    numConversations,
    title,
    verified,
  } = props;

  return (
    // const { props } = items;
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ marginLeft: 0, marginRight: 25 }}>
        <div
          style={{
            backgroundImage: `url(${profilePictureUrl})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundColor: 'black',
            height: 96,
            width: 96,
            // height: '100%',
            // width: '100%',
            overflow: 'auto',
            borderRadius: '1em',
          }}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ textAlign: 'left' }}>
          <h2 style={{ marginBottom: '0em' }}>
            <b style={{ color: COLOURS.red }}>{`${firstName || ''} ${
              lastName || ''
            }`}</b>
            &nbsp;
            {(isVerified || verified) && (
              <div style={{ position: 'absolute', display: 'inline' }}>
                <div
                  style={{
                    backgroundColor: 'white',
                    width: 15,
                    height: 15,
                    top: 15,
                    left: 7,
                    position: 'absolute',
                  }}
                />
                <div style={{ position: 'absolute', zIndex: 1 }}>
                  <HiBadgeCheck style={{ color: COLOURS.tickBlue }} />
                </div>
              </div>
            )}
          </h2>
          <h3 style={{ color: COLOURS.white, marginBottom: '0em' }}>{title}</h3>
          <span style={{ color: COLOURS.lightGrey }}>
            {numConversations} Bookings
          </span>
        </div>
      </div>
    </div>
  );
};

export const DetailedProfileView = (props) => {
  // const { props } = items;
  const router = useRouter();
  const {
    uuid,
    nonce,
    profileBannerUrl,
    topCategoryName,
    bookingUrl,
    professionalSummary,
  } = props;

  const filledHeartStyle = {
    color: COLOURS.red,
    width: 36,
    height: 36,
  };

  const outlinedHeartStyle = {
    color: '#FFFFFF94',
    width: 36,
    height: 36,
  };

  return (
    <div>
      <div
        style={{
          maxWidth: 500,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <BasicProfileView {...props} />
        <div style={{ marginTop: '1em' }} />
        {isString(topCategoryName) && topCategoryName.length > 0 && (
          <>
            <div style={{ marginTop: '1em' }}>
              <TopBadge
                topNumber={10}
                style={{
                  display: 'inline-block',
                  // marginLeft: '-1em'
                }}
              />
              <span style={{ fontSize: '1.25em', color: COLOURS.white }}>
                <b>&nbsp;&nbsp;&nbsp;#1 In {topCategoryName} Today</b>
              </span>
            </div>
            <div
              style={{
                textAlign: 'left',
                color: COLOURS.white,
                marginTop: '0.5em',
                marginBottom: '0.5em',
              }}
            >
              {/* <span style={{ fontSize: '1.25em' }}>{professionalSummary}</span> */}
            </div>
            <div style={{ marginTop: '1em' }} />
          </>
        )}
      </div>
      <div style={{ textAlign: 'left' }}>
        <BookMeetingButton
          style={{ marginRight: '1em' }}
          nonce={nonce}
          uuid={uuid}
          bookingUrl={bookingUrl}
          type="book"
        />
        <MoreInfoButton
          url={profileBannerUrl}
          style={{ marginRight: '0.5em' }}
        />
        {/* <WishListButton size="36" /> */}
      </div>
    </div>
  );
};

export const MiniProfileView = (props: any): JSX.Element => {
  const { handleAuthentication, isAuthenticated } = useUserInfo();
  const [showAddToWishList, setShowAddToWishList] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const baseUrl = getCooeeApiUrl(getHostEnv());
  const {
    firstName,
    lastName,
    verified,
    profilePictureUrl,
    conversation,
    title,
    nonce,
    profileBannerUrl,
    numConversations,
    style,
    uuid,
    inWishlist,
    bookingUrl,
  } = props;
  const [onMouseOverMiniProfile, setOnMouseOverMiniProfile] = useState(false);
  const { addToast } = useToasts();
  const handleShowAddToWishList = () => {
    setShowAddToWishList(!showAddToWishList);
  };

  const handleOnMouseEnter = () => {
    setOnMouseOverMiniProfile(true);
  };
  const handleOnMouseLeave = () => {
    setOnMouseOverMiniProfile(false);
  };

  // if (uuid === '1f5d9a31-92c4-4b57-accc-ce83890357a0') console.log(props);

  const handleAddToWishlistButtonClick = () => {
    (async () => {
      if (!isAuthenticated) handleAuthentication();
      else {
        try {
          const { status, data } = await axios.post(
            urlcat(baseUrl, 'user/wishlist'),
            {
              uuid,
              add: !inWishlist,
              source: 'others',
            }
          );
          if (status === 200) {
            // setIsInWishlist(!isInWishlist);
            addToast(
              !inWishlist
                ? `Added topic to wishlist`
                : `Removed topic from wishlist`,
              {
                appearance: 'success',
              }
            );
            try {
              const { status, data } = await axios.get(
                urlcat(baseUrl, `topics/${uuid}`)
              );
              if (data.data?.topic) {
                dispatch(updateTopic(data.data.topic));
              }
            } catch (error) {
              addToast(
                error.response.data.error.description ||
                  'Unable to refresh topic',
                {
                  appearance: 'warning',
                }
              );
              await logger.error(error);
            }
          }
        } catch (error) {
          addToast(
            error.response.data.error.description ||
              'Unable to add to wishlist',
            {
              appearance: 'warning',
            }
          );
          await logger.error(error);
        }
      }
    })();
  };

  return (
    <div
      className={styles.miniProfileZoom}
      style={{
        width: 211,
        height: 350,
        verticalAlign: 'bottom',
        ...style,
      }}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <div
        style={{
          height: 121,
          width: '100%',
          position: 'relative',
        }}
        onSelect={(event) => {
          // console.log(event.target);
          event.preventDefault();
          // @ts-ignore
          const targetAlt: any = event.target.alt;
          if (targetAlt === 'profile photo') {
            if (isAuthenticated) {
              router
                .push(
                  isString(nonce) && nonce.length > 0
                    ? `${urlcat(profileBannerUrl, {
                        uuid,
                        nonce,
                        conversation,
                        inWishlist,
                      })}`
                    : profileBannerUrl,
                  profileBannerUrl
                )
                .catch();
            } else handleAuthentication();
          }
        }}
        onClick={(event) => {
          // console.log(event.target);
          event.preventDefault();
          // @ts-ignore
          const targetAlt: any = event.target.alt;
          if (targetAlt === 'profile photo') {
            if (isAuthenticated) {
              router
                .push(
                  isString(nonce) && nonce.length > 0
                    ? `${urlcat(profileBannerUrl, {
                        uuid,
                        nonce,
                        conversation,
                        inWishlist,
                      })}`
                    : profileBannerUrl,
                  profileBannerUrl
                )
                .catch();
            } else handleAuthentication();
          }
        }}
      >
        <div className={styles.imgRoundedCorner}>
          <img
            src={urlcat('https://static.oncooee.com', profilePictureUrl)}
            alt="profile photo"
          />
        </div>
        <div style={{ position: 'absolute', top: 5, right: verified ? 30 : 5 }}>
          <div className="d-flex flex-row">
            {!inWishlist && (
              <div
                className={
                  showAddToWishList
                    ? styles.showAddToWishList
                    : styles.hideAddToWishList
                }
              >
                <span style={{ fontSize: 13 }}>Add to my wish list</span>
              </div>
            )}
            <div
              className="p-0"
              onMouseEnter={handleShowAddToWishList}
              onMouseLeave={handleShowAddToWishList}
            >
              <Button
                style={{
                  backgroundColor: 'transparent',
                  width: 18,
                  height: 18,
                  verticalAlign: 'top',
                  padding: 0,
                  borderStyle: 'none',
                }}
                onClick={handleAddToWishlistButtonClick}
              >
                {inWishlist ? (
                  <AiFillHeart size={18} style={{ color: 'red' }} />
                ) : (
                  <AiOutlineHeart size={18} style={{ color: '#FFFFFF94' }} />
                )}
              </Button>
            </div>
            &nbsp;
            {verified && (
              <div className="p-0" style={{ position: 'relative' }}>
                <>
                  <div
                    style={{
                      position: 'absolute',
                      backgroundColor: 'white',
                      width: 10,
                      height: 10,
                      top: 9,
                      left: 5,
                    }}
                  />
                  {verified ? (
                    <div style={{ position: 'absolute', zIndex: 1 }}>
                      <AiFillCheckCircle
                        size={18}
                        style={{ color: '#424EFC' }}
                      />
                    </div>
                  ) : (
                    <div style={{ position: 'absolute', zIndex: 1 }}>
                      <AiFillExclamationCircle
                        size={18}
                        style={{ color: '#424EFC' }}
                      />
                    </div>
                  )}
                </>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            top: 80,
            left: 11,
          }}
        >
          <p style={{ textShadow: '1px 1px 1px #000000', color: '#FFFFFFCC' }}>
            {numConversations || 0} Bookings
          </p>
        </div>
        {/* &nbsp;&nbsp;{props.title} */}
      </div>
      <div
        style={{
          wordWrap: 'break-word',
          overflowWrap: 'break-word',
          marginBottom: 7,
        }}
        onSelect={(event) => {
          // console.log(event.target);
          event.preventDefault();
          // @ts-ignore
          const targetAlt: any = event.target.alt;
          if (targetAlt === 'profile photo') {
            if (isAuthenticated) {
              router
                .push(
                  isString(nonce) && nonce.length > 0
                    ? `${urlcat(profileBannerUrl, {
                        uuid,
                        nonce,
                        conversation,
                        inWishlist,
                      })}`
                    : profileBannerUrl,
                  profileBannerUrl
                )
                .catch();
            } else handleAuthentication();
          }
        }}
        onClick={(event) => {
          event.preventDefault();
          if (isAuthenticated) {
            router
              .push(
                isString(nonce) && nonce.length > 0
                  ? `${urlcat(profileBannerUrl, {
                      uuid,
                      nonce,
                      conversation,
                      inWishlist,
                    })}`
                  : profileBannerUrl,
                profileBannerUrl
              )
              .catch();
          } else handleAuthentication();
        }}
      >
        <span
          style={{
            fontSize: '1.15rem',
            width: 211,
            fontWeight: 'bold',
            whiteSpace: 'normal',
          }}
        >
          {conversation}
        </span>
      </div>

      <div>
        <span style={{ fontWeight: 600 }}>
          {firstName} {lastName}
        </span>
      </div>
      <div>
        <span style={{ fontFamily: '' }}>{title}</span>
      </div>
      {onMouseOverMiniProfile ? (
        <div
          className={styles.miniProfileOptions}
          style={{ marginTop: 7, textAlign: 'left' }}
        >
          <BookMeetingButton
            style={{
              marginRight: '0.4em',
              color: 'white',
              backgroundColor: COLOURS.red,
              borderColor: COLOURS.red,
              fontSize: '1.0em',
            }}
            nonce={nonce}
            uuid={uuid}
            bookingUrl={bookingUrl}
            type="book"
          />
          <MoreInfoButton
            url={profileBannerUrl}
            style={{
              fontSize: '1.0em',
              // width: 120,
            }}
            nonce={nonce}
            topicUuid={uuid}
            topicName={conversation}
            topicInWishlist={inWishlist}
          />
        </div>
      ) : null}
    </div>
  );
};
