import Button from 'react-bootstrap/Button';
import { FaRegCalendarCheck } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { isString } from 'lodash';
import { COLOURS } from '../../styles/Constants';
import useUserInfo from '../../hooks/useUserInfo';
import styles from './BookingMeetingButton.module.css';
import { setModalOptions } from '../../store/actions/appActions';
import urlcat, { join, query } from 'urlcat';
import axios from '../../utils/axios';
import logger from '../../utils/logger';
import { getCooeeApiUrl } from '../../utils/api';
import { getHostEnv } from '../../utils/api';

export const BookMeetingButton = (props: {
  type?: string;
  style?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  nonce?: string;
  uuid?: string;
  bookingUrl?: string;
}): JSX.Element => {
  // @ts-ignore
  const { isAuthenticated, handleAuthentication } = useUserInfo();
  const dispatch = useDispatch();
  const { type, uuid, nonce, bookingUrl } = props;
  const { userInfo } = useUserInfo();
  const baseUrl = getCooeeApiUrl(getHostEnv());

  const buildDynamicUrlQuery = () => {
    let params: any = {};
    params.firstName = userInfo.givenName;
    params.lastName = userInfo.familyName;
    params.email = userInfo.email;
    params.phone = userInfo.phone;
    // params.calendarID = '';
    // params.appointmentType = '';
    // params.location = '';
    // params.datetime = '';
    return query(params);
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    if (isAuthenticated && isString(bookingUrl) && bookingUrl.length > 0) {
      const extraParams = buildDynamicUrlQuery();
      const fullUrl = join(bookingUrl, '&', extraParams);
      dispatch(setModalOptions({ show: true, url: fullUrl }));
      (async () => {
        try {
          await axios.post(urlcat(baseUrl, `public/register_visit`), {
            url: window.location,
            data: { nonce, uuid },
            type: type || 'unknown',
          });
        } catch (error) {
          await logger.error(error);
        }
      })();
    } else {
      handleAuthentication();
    }
  };

  return (
    <Button
      style={{
        fontSize: '1.3em',
        padding: 'auto auto',
        backgroundColor: COLOURS.white,
        color: COLOURS.black,
        borderColor: COLOURS.white,
        ...props.style,
      }}
      onClick={handleButtonClick}
    >
      <FaRegCalendarCheck
        style={{ marginBottom: '0.3em', ...props.iconStyle }}
      />
      &nbsp;Book
    </Button>
  );
};
