import React from 'react';
import { useRouter } from 'next/router';
import Button from 'react-bootstrap/Button';
import { MdInfoOutline } from 'react-icons/md';
import { isString } from 'lodash';
import urlcat from 'urlcat';
import { COLOURS } from '../../styles/Constants';
import useUserInfo from '../../hooks/useUserInfo';

export const MoreInfoButton = (props): JSX.Element => {
  const { style, url, topicUuid, nonce, topicName, topicInWishlist } = props;
  const router = useRouter();
  const { isAuthenticated, handleAuthentication } = useUserInfo();

  return (
    <Button
      style={{
        fontSize: '1.3em',
        padding: 'auto auto',
        backgroundColor: COLOURS.darkGreyTransparent,
        color: COLOURS.white,
        borderColor: COLOURS.darkGreyTransparent,
        ...style,
      }}
      href={url}
      onClick={(event) => {
        event.preventDefault();
        if (isAuthenticated) {
          router
            .push(
              isString(nonce) && nonce.length > 0
                ? `${urlcat(url, {
                    topicUuid,
                    nonce,
                    topicName,
                    topicInWishlist,
                  })}`
                : url,
              url
            )
            .catch();
        } else handleAuthentication();
      }}
    >
      <div style={{ display: 'inline-block' }}>
        <MdInfoOutline style={{ marginBottom: '0.3em' }} />
        &nbsp;More Info
      </div>
    </Button>
  );
};
