import { COLOURS } from '../styles/Constants';
import React from 'react';

interface BadgeProps {
  topNumber: number;
  style?: React.CSSProperties;
}

export const TopBadge = (props: BadgeProps): JSX.Element => {
  return (
    <div style={props.style}>
      <div
        style={{
          border: `4px solid ${COLOURS.white}`,
          display: 'inline-block',
        }}
      >
        <span style={{ color: COLOURS.white, fontWeight: 'bold' }}>
          <div style={{ fontSize: '0.7em', marginBottom: '-0.5em' }}>
            &nbsp;&nbsp;TOP&nbsp;&nbsp;
          </div>
          {props.topNumber}
        </span>
      </div>
    </div>
  );
};
